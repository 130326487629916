import { defineStore } from "pinia";
import apiService from "@/api/ApiService";

const TICKET_MANAGEMENT_URL = "/ticket-management/";
const PAYMENT_METHODS_URL = "/payment-methods/";
const PAY_LATE_SELL = "/late-sell-payments/";
const TICKET_TYPES_URL = "/ticket-types/";
const PURCHASE_URL = "/purchases/";
const CROSS_SELL_URL = "/cs-purchase-update/";
const COMPLEMENTARY_URL = "/complementary-purchase/";
const BUYERS_URL = "/buyers/";
const CHANGE_ADDRESS_FREE_URL = "/ticket-management/update-ticket/";

export const useTicketStore = defineStore("ticket", {
  state: () => {
    return {
      ticket: null,
      originalPrice: null,
      prices: null,
      ticketHistory: [],
      upgradableTickets: [],
      attendeeFields: {
        personal: false,
        personal_address: false,
        personal_birthday: false,
        personal_email: false,
        personal_gender: false,
        personal_id: false,
        personal_phone: false,
        locality: false,
        state: false,
      },
      new_attendee: null,
      new_user: null,
      transaction_type: null,
      payment_methods: null,
      procedure_data: null,
      upgradePurchase: null,
      complementaryPurchase: null,
      complementaryProductsPurchased: null,
      buyer: null,
      loginTicketError: null,
      eventValidError: null,
      ticketError: null,
      paymentError: null,
      purchaseError: null,
      reservationError: null,
      complementaryProductsError: null,
      buyerError: null,
      changeAddressError: null,
    };
  },

  actions: {
    async loginMyTicket(payload) {
      const params = {
        email: payload.email,
        ticket_code: payload.ticketCode,
      };
      this.loginTicketError = null;
      this.eventValidError = null;
      this.ticket = null;
      try {
        const response = await apiService.post(
          TICKET_MANAGEMENT_URL + "login/",
          params
        );
        if (
          response.data.Error === "Event has not ticket management activated"
        ) {
          this.eventValidError = response.data.Error;
        } else if (response.data.Error === "Combination does not exist") {
          this.loginTicketError = response.data.Error;
        } else {
          this.ticket = response.data;
          this.prices = response.data.prices;
          this.originalPrice = response.data.price - response.data.discount;
        }
      } catch (error) {
        console.log("error", error);
        this.loginTicketError = error.Error;
      }
    },

    async getUpgradableTickets(payload) {
      const params = {
        event: this.ticket.event.id,
        upgrade_of: this.ticket.ticket_type.id,
      };
      console.log("params :>> ", params);
      this.upgradableTickets = [];
      try {
        const response = await apiService.get(TICKET_TYPES_URL, {
          params,
        });
        console.log("response :>> ", response);
        this.upgradableTickets = response.data.ticket_types;
      } catch (error) {
        this.ticketError = error;
      }
    },

    async getHistory() {
      const params = {
        ticket_code: this.ticket.short_validation_code,
      };
      this.ticketHistory = [];
      try {
        const response = await apiService.get(
          TICKET_MANAGEMENT_URL + "ticket-history/",
          { params }
        );
        this.ticketHistory = response.data;
      } catch (error) {
        this.ticketError = error;
      }
    },

    async getComplementaryProductsPurchased(payload) {
      this.complementaryProductsPurchased = null;
      this.complementaryProductsError = null;
      const params = {
        ticket: this.ticket.id,
        purchase: this.ticket.purchase,
      };
      try {
        const response = await apiService.get(COMPLEMENTARY_URL, { params });
        this.complementaryProductsPurchased = response.data;
      } catch (error) {
        this.complementaryProductsError = error;
      }
    },

    async getPaymentMethods(amount = this.ticket.price) {
      const params = {
        event: this.ticket.event.id,
        amount: amount,
      };
      this.payment_methods = null;
      try {
        const response = await apiService.get(PAYMENT_METHODS_URL, { params });
        this.payment_methods = response.data;
      } catch (error) {
        this.ticketError = error;
      }
    },
    async createBuyer() {
      const params = {
        email: this.ticket.owner.email,
        first_name: "Buyer Management",
        last_name: this.ticket.ticket_type.id,
        newsletter: false,
        privacy_policy: true,
        location: {
          id: 0,
          name: "",
          place_id: "",
          longitude: 0,
          latitude: 0,
        },
      };
      this.buyerError = null;
      this.buyer = null;
      try {
        const response = await apiService.post(BUYERS_URL, params);
        console.log("response :>> ", response);
        this.buyer = response.data;
      } catch (error) {
        console.log("error :>> ", error);
        this.buyerError = error;
      }
    },
    async createPurchase(upgradeTicketId, shortValidationCode) {
      const params = {
        tickets: [
          {
            ticket_type: upgradeTicketId,
          },
        ],
        upgrade_ticket: shortValidationCode,
      };
      if(this.buyer){
        params.buyer = this.buyer.id;
      }
      this.purchaseError = null;
      try {
        const response = await apiService.post(PURCHASE_URL + '?v4=true', params);
        console.log("response :>> ", response);
        if (response.data) {
          this.upgradePurchase = {
            reference: response.data.reference,
            id: response.data.id,
            newTicketType: upgradeTicketId,
          };
        }
      } catch (error) {
        this.purchaseError = error;
      }
    },

    async createReservation(payload) {
      this.reservationError = null;
      try {
        const response = await apiService.post(CROSS_SELL_URL, payload);
        if (response.data) {
          this.complementaryPurchase = {
            reference: response.data.complementary_purchase.reference,
            id: response.data.complementary_purchase.purchase_id,
          };
        }
      } catch (error) {
        this.reservationError = error;
      }
    },

    async completePurchase(payload) {
      console.log("payload", payload);
      this.paymentError = null;
      try {
        const response = await apiService.post(PAY_LATE_SELL, payload);
        if (
          !response.data.accepted ||
          (response.data.error_code &&
            response.data.error_code.includes("WEGOW"))
        ) {
          this.paymentError = response.data.error_code;
        }
        if (!response.data.accepted && response.data.procedure_data) {
          this.procedure_data = response.data.procedure_data;
        }
      } catch (error) {
        this.paymentError = error;
      }
    },

    async getAttendeeFields() {
      const params = {
        event: this.ticket.event.id,
        is_streaming: false,
        page: 1,
        mongo: true,
        ticket_management: true,
      };
      try {
        const response = await apiService.get(
          TICKET_TYPES_URL + this.ticket.ticket_type.id + "/",
          { params }
        );
        this.attendeeFields.personal = response.data.personal;
        this.attendeeFields.personal_address = response.data.personal_address;
        this.attendeeFields.personal_birthday = response.data.personal_birthday;
        this.attendeeFields.personal_email = response.data.personal_email;
        this.attendeeFields.personal_gender = response.data.personal_gender;
        this.attendeeFields.personal_id = response.data.personal_id;
        this.attendeeFields.personal_phone = response.data.personal_phone;

        this.attendeeFields.state = response.data.state;
        this.attendeeFields.locality = response.data.locality;
        this.attendeeFields.country = response.data.country;
        this.attendeeFields.postal_code = response.data.postal_code;
        this.attendeeFields.personal_phone =
          response.data.attendee.phone_number;
      } catch (error) {
        this.ticketError = error;
      }
    },

    setNewAttendee(payload) {
      this.new_attendee = payload;
      this.transaction_type = "modifyDataPayment";
      console.log("this.new_attendee :>> ", this.new_attendee);
    },

    setNewUser(payload) {
      this.new_user = payload;
      this.transaction_type = "transferPayment";
    },

    async changeAddressFree(payload) {
      console.log('payload', payload)
      this.changeAddressError = null;
      try {
        const response = await apiService.put(CHANGE_ADDRESS_FREE_URL, payload);
        console.log('response', response);
      } catch (error) {
        this.changeAddressError = error;
      }
    }
  },
});